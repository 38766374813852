<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="4"
        ><el-input
          size="small"
          v-model="queryFilter.ids"
          placeholder="请输入筛选ID多个ID使用,分割"
          clearable
      /></el-col>

      <el-col :span="4"
        ><el-input
          size="small"
          v-model="queryFilter.keyWorlds"
          placeholder="请输入刻字订单相关信息进行筛选"
          clearable
      /></el-col>

      <el-col :span="4">
        <el-select
          size="small"
          v-model="queryFilter.status"
          clearable
          placeholder="筛选刻字订单状态"
        >
          <el-option
            v-for="item in letteringOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="7">
        <el-date-picker
          size="small"
          v-model="timer"
          type="datetimerange"
          align="right"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-col>
      <el-button size="small" type="primary" @click="flashTable"
        >查询订单</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table
        :data="tableData"
        stripe
        row-key="id"
        style="width: 100%"
        border
        @expand-change="expandChange"
      >
        <!-- 编号快递信息折叠消息 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div
              v-for="item in scope.row.letteringDeliveries"
              :key="item.id"
              v-loading="scope.row.loading"
              style="margin: 20px"
            >
              <span
                >快递单号:<span style="color: red">{{
                  item.courierNumber
                }}</span></span
              >
              <span>发出时间:{{ item.createTime }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column type="index" label="#"> </el-table-column>
        <el-table-column width="50" prop="id" label="订单ID"> </el-table-column>
        <el-table-column label="订单编号">
          <template slot-scope="scope">
            <el-link
              :underline="false"
              @click="handleCopy(scope.row.number, $event)"
              >{{ scope.row.number }}</el-link
            >
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="供应商名称"
          prop="supplierName"
        ></el-table-column> -->
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-tag :type="tagType(scope.row.letteringState)" effect="dark">
              {{ scope.row.letteringStateDisplayName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column width="80" label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isCheck"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="switchOrderState(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column label="编辑">
          <template slot-scope="scope">
            <el-button
            type="text"
              size="mini"
              icon="el-icon-download"
              @click="
                downloadExcelFiel(
                  scope.row.fileUrl,
                  scope.row.id + '_' + scope.row.linkeOrderNumber
                )
              "
              >下载</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="
                openNewWindow(
                  'https://view.officeapps.live.com/op/view.aspx?src=' +
                    encodeURI(scope.row.fileUrl)
                )
              "
              >预览</el-button
            >
            <el-button
              size="mini"
              type="text"
              icon="el-icon-truck"
              @click="openDeliveryDialog(scope.row)"
              >去出库</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-pagination
      style="margin-top: 20px; text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryFilter.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryFilter.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="queryFilter.total"
    >
    </el-pagination>

    <el-dialog
      @close="sendDeliveryDialogClose"
      :visible.sync="sendDeliveryDialogVisable"
    >
      <el-form>
        <el-form-item label="快递单号">
          <el-input
            v-model="sendDeliveryFrom.courierNumber"
            placeholder="请填写当前刻字订单发送出的快递单号"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="sendDeliveryDialogVisable = false"
          >取消</el-button
        >
        <el-button type="primary" @click="sendDeliveryDialogComfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { timeOffset } from '@/utils/zoneTime.js'
import clip from '@/utils/clipboard'
import {
  downloadFile,
  getLetteringById,
  getSupplierLetteringList,
  changeLetteringCheckState,
  sendLetteringDeivery,
} from '@/api/lettering.js'
export default {
  data() {
    return {
      supplierOptions: [],
      timer: '',
      // 筛选条件
      queryFilter: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        keyWorlds: '',
        supplierId: null,
        status: null,
        ids: '',
      },
      tableData: [],
      letteringOptions: [
        {
          label: '审核通过',
          value: 3,
        },
        {
          label: '货物发出',
          value: 5,
        },
      ],
      sendDeliveryDialogVisable: false,
      sendDeliveryFrom: {
        letteringId: null,
        courierNumber: '',
      },
    }
  },
  created() {
    this.getTableData()
  },
  methods: {
    handleCopy(text, event) {
      if (text !== '') {
        clip(text, event)
      }
    },
    async downloadExcelFiel(url, fileName) {
      await downloadFile(url, fileName)
    },
    async getTableData() {
      let filterData = { ...this.queryFilter }

      if (this.timer) {
        filterData.startTime = timeOffset(this.timer[0])
        filterData.endTime = timeOffset(this.timer[1])
      }

      let { data } = await getSupplierLetteringList(filterData)

      this.tableData = data.data.map((item) => {
        item.loading = false
        item.detail = null
        return item
      })

      this.queryFilter.total = data.total
    },
    handleSizeChange(val) {
      this.queryFilter.pageSize = val
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.queryFilter.pageIndex = val
      this.getTableData()
    },
    flashTable() {
      this.queryFilter.pageIndex = 1
      this.getTableData()
    },
    tagType(val) {
      let type = 'info'
      if (val == 1) {
        type = 'info'
      }
      if (val == 2) {
        type = 'info'
      }

      if (val == 3) {
        type = 'warning'
      }
      if (val == 4) {
        type = 'danger'
      }

      if (val == 5) {
        type = 'success'
      }
      return type
    },
    openNewWindow(url) {
      window.open(url, '_blank')
    },
    async expandChange(row, expandedRows) {
      row.loading = true
      try {
        let { data } = await getLetteringById(row.id)
        row.letteringDeliveries = data.letteringDeliveries
        row.loading = false

        console.log(row)
      } catch (error) {
        row.loading = false
      }
    },
    openDeliveryDialog(row) {
      this.sendDeliveryFrom.letteringId = row.id
      this.sendDeliveryDialogVisable = true
    },
    sendDeliveryDialogClose() {
      this.sendDeliveryFrom = {
        letteringId: null,
        courierNumber: '',
      }
    },
    async sendDeliveryDialogComfirm() {
      if (
        !this.sendDeliveryFrom.letteringId ||
        !this.sendDeliveryFrom.courierNumber
      ) {
        return this.$message.error('当前出库信息不完整,出库失败')
      }

      await sendLetteringDeivery(this.sendDeliveryFrom)
      this.$message.success('刻字订单出库成功')
      this.getTableData()
      this.sendDeliveryDialogVisable = false
    },
    async switchOrderState(row) {
      try {
        let { data } = await changeLetteringCheckState(row.id)
        row.isCheck = data
      } catch (error) {
        row.isCheck = !row.isCheck
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
